import FooterComponent from "../../Components/Footer/FooterComponent";
import HeaderComponent from "../../Components/Header/Immobili/HeaderComponent";
import MessageOverlayBanner from "../../Components/MessageOverlayBanner/MessageOverlayBannerComponent";
import NavComponent from "../../Components/Nav/NavComponent";
import "./Servizi.css";

function Servizi(){
    return (
        <>
            <MessageOverlayBanner/>
            <NavComponent/>
            <HeaderComponent Title="Servizi"/>
            <section className="m-auto servizi-section-bg-container">
                <div className="m-auto servizi-section-bg"></div>
            </section>
            <section className="m-auto servizi-section">
                <div className="w-100 m-auto pt-5 servizi-section-item left">
                    <div className="m-auto servizi-section-item-img">
                        <img src="https://images.unsplash.com/photo-1503174971373-b1f69850bded?q=80&w=3613&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
                    </div>
                    <div className="m-auto servizi-section-item-text">
                        <h4 className="servizi-section-item-title">
                            Servizio 1
                        </h4>
                        <p className="servizi-section-item-p">
                        Lorem ipsum dolor sit amet consectetur adipiscing 
                        elit Ut et massa mi. Aliquam in hendrerit urna. 
                        Pellentesque sit amet sapien fringilla, mattis 
                        ligula consectetur, ultrices mauris. Maecenas 
                        vitae mattis tellus. Nullam quis imperdiet augue. 
                        Vestibulum auctor ornare leo, non suscipit magna 
                        interdum eu. Curabitur pellentesque nibh nibh, at 
                        maximus ante fermentum sit amet. Pellentesque 
                        commodo lacus at sodales sodales. Quisque.
                        </p>
                    </div>
                </div>
                <div className="w-100 m-auto servizi-section-item right">
                    <div className="m-auto servizi-section-item-text">
                        <h4 className="servizi-section-item-title">
                            Servizio 2
                        </h4>
                        <p className="servizi-section-item-p">
                        Lorem ipsum dolor sit amet consectetur adipiscing 
                        elit Ut et massa mi. Aliquam in hendrerit urna. 
                        Pellentesque sit amet sapien fringilla, mattis 
                        ligula consectetur, ultrices mauris. Maecenas 
                        vitae mattis tellus. Nullam quis imperdiet augue. 
                        Vestibulum auctor ornare leo, non suscipit magna 
                        interdum eu. Curabitur pellentesque nibh nibh, at 
                        maximus ante fermentum sit amet. Pellentesque 
                        commodo lacus at sodales sodales. Quisque.
                        </p>
                    </div>
                    <div className="m-auto servizi-section-item-img">
                        <img src="https://images.unsplash.com/photo-1503174971373-b1f69850bded?q=80&w=3613&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
                    </div>
                </div>
                <div className="w-100 m-auto servizi-section-item left">
                    <div className="m-auto servizi-section-item-img">
                        <img src="https://images.unsplash.com/photo-1503174971373-b1f69850bded?q=80&w=3613&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
                    </div>
                    <div className="m-auto servizi-section-item-text">
                        <h4 className="servizi-section-item-title">
                            Servizio 3
                        </h4>
                        <p className="servizi-section-item-p">
                        Lorem ipsum dolor sit amet consectetur adipiscing 
                        elit Ut et massa mi. Aliquam in hendrerit urna. 
                        Pellentesque sit amet sapien fringilla, mattis 
                        ligula consectetur, ultrices mauris. Maecenas 
                        vitae mattis tellus. Nullam quis imperdiet augue. 
                        Vestibulum auctor ornare leo, non suscipit magna 
                        interdum eu. Curabitur pellentesque nibh nibh, at 
                        maximus ante fermentum sit amet. Pellentesque 
                        commodo lacus at sodales sodales. Quisque.
                        </p>
                    </div>
                </div>
            </section>
            <FooterComponent/>
        </>
    )
}

export default Servizi;