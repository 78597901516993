import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterComponent from "../../Components/Footer/FooterComponent";
import HeaderComponent from "../../Components/Header/Immobili/HeaderComponent";
import MailToComponent from "../../Components/MailTo/MailToComponent";
import MessageOverlayBanner from "../../Components/MessageOverlayBanner/MessageOverlayBannerComponent";
import NavComponent from "../../Components/Nav/NavComponent";
import { AdImgModel, AdModel } from "../../Models/AdModel";
import LoaderComponent from "../../RTFramework/Loader/LoaderComponent";
import { apiGet } from "../../RTFramework/Services/Api";
import { WhereCondition } from "../../RTFramework/Utility/StringOption";
import "./Immobile.css";
import ChatToComponent from "../../Components/ChatTo/ChatToComponent";

const controllerName = "Ad";
const callbackFunction = "Get";

const getAd = async (id: string) => {
    const whereCondition = [new WhereCondition("Id", id)];

    return ((await apiGet(controllerName, callbackFunction, 1, 0, whereCondition, [], false)).data as AdModel[])[0];
}

function Immobile(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('Home') || '';
    const [ad, setAd] = useState<AdModel | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedImg, setSelectedImg] = useState<AdImgModel>();
    const [selectedImgIndex, setSelectedImgIndex] = useState(0);

    useEffect(() => {
        if(id && parseInt(id)){
            getAd(id)
                .then(response => {
                    setAd(response);
                    setSelectedImg(response?.Imgs[selectedImgIndex]);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Errore: ", error);
                    setLoading(false);
                });
        }
    }, [id]);

    const handleImgClick = (img: AdImgModel, index: number) => {
        setSelectedImg(img);
        setSelectedImgIndex(index);
    };

    const handlePrevClick = () => {
        if (!ad?.Imgs || ad.Imgs.length === 0) return;
        const prevIndex = (selectedImgIndex - 1 + ad.Imgs.length) % ad.Imgs.length;
        setSelectedImgIndex(prevIndex);
        setSelectedImg(ad.Imgs[prevIndex]);
    };
    
    const handleNextClick = () => {
        if (!ad?.Imgs || ad.Imgs.length === 0) return;
        const nextIndex = (selectedImgIndex + 1) % ad.Imgs.length;
        setSelectedImgIndex(nextIndex);
        setSelectedImg(ad.Imgs[nextIndex]);
    };

    return(
        <>
            <MessageOverlayBanner/>
            <NavComponent/>
            {loading && <LoaderComponent/>}
            {ad && 
                <>
                <HeaderComponent Title={ad?.Titolo!} SrcImg={ad?.Imgs[0].Src}/>
                <MailToComponent subject={"Richiesta informazioni per immobile: " + ad?.Titolo!} body={"Buongiorno, avrei bisogno di informazioni riguardo l'immobile " + ad?.Titolo!} />
                <ChatToComponent subject={"Richiesta informazioni per immobile: " + ad?.Titolo!} body={"Buongiorno, avrei bisogno di informazioni riguardo l'immobile " + ad?.Titolo!} />
                <section className="description-bar-container description-container-grid mt-4">
                    <div className="description-item">
                    <h4>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(ad?.Prezzo ?? 0)}</h4>
                    </div>
                    <div className="description-item">
                        <h4>{ad?.Type}</h4>
                    </div>
                    <div className="description-item">
                        <h4>{ad?.Metratura} m²</h4>
                    </div>
                    <div className="description-item">
                        <h4>{ad?.Vani} Locali</h4>
                    </div>
                </section>
                <div className="m-auto immobile-section-bg-container">
                    <div className="m-auto immobile-section-bg"></div>
                </div>
                <div className="m-auto immobile-section">
                    <section className="immobile-section-carousel" aria-labelledby="image-carousel">
                        <div className="immobile-carousel-img">
                            <button 
                                className="carousel-button prev-button" 
                                onClick={() => handlePrevClick()}
                                aria-label="Immagine precedente"
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <img 
                                key={selectedImg?.Id}
                                src={selectedImg?.Src}
                                alt={`Immagine ${selectedImg?.Id}`}
                            />
                            <button 
                                className="carousel-button next-button" 
                                onClick={() => handleNextClick()}
                                aria-label="Immagine successiva"
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>
                        <div className="immobile-carousel-imgs-container" aria-label="Miniature della galleria immagini">
                            {ad?.Imgs.map((img, index) => (
                                <img 
                                    key={index}
                                    src={img.Src}
                                    alt={`Immagine ${index}`}
                                    onClick={() => handleImgClick(img, index)}
                                />
                            ))}
                        </div>
                    </section>
                    <section className="immobile-section-description" role="region" aria-labelledby="address">
                        <h4 id="address" className="immobile-description-title">Indirizzo</h4>
                        <hr className="immobile-description-hr"/>
                        <p className="immobile-description-p">
                            <a className="location-link mx-2" 
                                href={"https://www.google.com/maps/search/?api=1&query=" + encodeURI(ad?.Indirizzo + ", " + ad?.Comune + " (" + ad?.Provincia + ")")}
                                target="_blank" rel="noopener noreferrer"
                                aria-label={`Apri la mappa per l'indirizzo ${ad?.Indirizzo}, ${ad?.Comune} (${ad?.Provincia})`}>
                                <i className="fas fa-map-marked-alt" aria-hidden="true"></i>
                            </a> 
                            {ad?.Indirizzo + ", " + ad?.Comune + " (" + ad?.Provincia + ")"}
                        </p>
                    </section>
                    <section className="immobile-section-description" role="region" aria-labelledby="description">
                        <h4 id="description" className="immobile-description-title">Descrizione</h4>
                        <hr className="immobile-description-hr"/>
                        <p className="immobile-description-p">
                            {ad?.Descrizione}
                        </p>
                    </section>
                    <section className="immobile-section-caratteristiche" role="region" aria-labelledby="features">
                        <h4 id="features" className="immobile-caratteristiche-title">Caratteristiche</h4>
                        <hr className="immobile-caratteristiche-hr"/>
                        <div className="immobile-caratteristiche-container">
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Tipologia:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Type}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Piano:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Piano}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Superficie:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Metratura == "0" ? "-" : ad?.Metratura} m²</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Balconi:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Balconi == 0 ? "-" : ad?.Balconi}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Locali:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Vani == 0 ? "-" : ad?.Vani}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Terrazzi:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Terrazzi == 0 ? "-" : ad?.Terrazzi}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Camere:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Camere == 0 ? "-" : ad?.Camere}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Box:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Box == 0 ? "-" : ad?.Box}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Bagni:</div>
                            <div className="immobile-caratteristiche-item-value">{ad?.Bagni == 0 ? "-" : ad?.Bagni}</div>
                        </div>
                        <div className="immobile-caratteristiche-item">
                            <div className="immobile-caratteristiche-item-title">Classe energetica:</div>
                            <div className="immobile-caratteristiche-item-value" dangerouslySetInnerHTML={{ __html: ad?.Classe }}></div>
                        </div>
                            {/*ad?.Features.map((feature, index) => (
                                <div className="immobile-caratteristiche-item" key={"feature-"+index}>
                                    <div className="immobile-caratteristiche-item-title">{feature.Title}:</div>
                                    <div className="immobile-caratteristiche-item-value">{feature.Value}</div>
                                </div>
                            ))*/}
                        </div>
                    </section>
                </div>
                </>}
            <FooterComponent/>
        </>
    )
}

export default Immobile;