import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardWrapperComponent } from "../Components/Card/CardComponent";
import FilterComponent from "../Components/Filter/FilterComponent";
import FooterComponent from "../Components/Footer/FooterComponent";
import HeaderComponent from "../Components/Header/Immobili/HeaderComponent";
import MessageOverlayBanner from "../Components/MessageOverlayBanner/MessageOverlayBannerComponent";
import NavComponent from "../Components/Nav/NavComponent";
import { useAdsFilter } from "../Hooks/useAdsFilter";
import "./Home/Home.css";

const adsLimit = 5;

function Immobili() {
  const { filteredAds, setFilter, fetchMoreAds, hasMoreData, loading } = useAdsFilter(adsLimit);
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  
  /*useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tipo = queryParams.get("Tipologia") || sessionStorage.getItem("filterTipologia") || "";
    const vani = parseInt(queryParams.get("Vani") || sessionStorage.getItem("filterVani") || "") || null;
    const minPrice = parseInt(queryParams.get("MinPrice") || sessionStorage.getItem("filterMinPrice") || "0") || 0;
    const maxPrice = parseInt(queryParams.get("MaxPrice") || sessionStorage.getItem("filterMaxPrice") || (tipo === "Affitto" ? "5000" : "5000000")) || 5000000;

    setFilter({ tipo, vani, minPrice, maxPrice });
  }, [location.search, setFilter]);*/

  // Intersection Observer per il caricamento dinamico
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && hasMoreData) {
          fetchMoreAds(filteredAds.length);
        }
      },
      { 
        root: null,
        rootMargin: "0px",
        threshold: 1.0 
      }
    );

    if (bottomBoundaryRef.current) observer.observe(bottomBoundaryRef.current);

    return () => {
      if (bottomBoundaryRef.current) observer.unobserve(bottomBoundaryRef.current);
    };
  }, [fetchMoreAds, hasMoreData, loading, filteredAds.length]);

    return (
        <>
            <MessageOverlayBanner />
            <NavComponent />
            <HeaderComponent Title="Immobili" />
            <FilterComponent onFilterChange={setFilter} />
            <CardWrapperComponent listCard={filteredAds} />
            <div ref={bottomBoundaryRef}></div>
            <FooterComponent />
        </>
    );
}

export default Immobili;
