import "./Footer.css";

function FooterComponent(){
    const mail = "info@lacasapatella.it";
    const phone = ["+390229529855", "+390229515325"];

    return(
        <footer className="" role="contentinfo" aria-labelledby="footer-heading">
            <section className="row row-cols-1 row-cols-sm-2 row-cols-md-5 mt-5 px-5 py-5 border-top position-relative w-100">
                <div className="col mb-3 txt-grey">
                    <a href="/Home" className="d-flex align-items-center mb-3 m-auto link-dark text-decoration-none" aria-label="Vai alla home La Casa Patella">
                        <img className="navbar-brand logo" src="/logogreen.png" alt="Logo La Casa Patella"/>
                    </a>
                </div>
                <div className="col mb-3 txt-black">
                    <h5 id="footer-heading">Contatti</h5>
                    <hr className="card-border w-50"/>
                    <ul className="nav flex-column" aria-labelledby="footer-heading">
                        <li className="mb-2">
                            <address className="nav-link p-0 text-muted" aria-label="Indirizzo">Viale Vittorio Veneto, 18</address>
                        </li>
                        <li className="mb-2">
                            <section className="nav-link p-0 text-muted" aria-label="Città e CAP">20124 Milano - MI</section>
                        </li>
                        <li className="nav-item mb-2">
                            <a href={"mailto:" + mail} className="nav-link p-0 text-muted" aria-label={"Email: " + mail}>{mail}</a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href={"tel:" + phone[0]} className="nav-link p-0 text-muted" aria-label={"Telefono: " + phone[0]}>{phone[0]}</a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href={"tel:" + phone[1]} className="nav-link p-0 text-muted" aria-label={"Telefono: " + phone[1]}>{phone[1]}</a>
                        </li>

                    </ul>
                </div>
                <div className="col mb-3 txt-black">
                    <h5>Servizi</h5>
                    <hr className="card-border w-50"/>
                    <ul className="nav flex-column" aria-label="Servizi offerti">
                        <li className="nav-item mb-2">
                            <a href={"/Immobili?Tipologia="+encodeURIComponent("Affitto")} className="nav-link p-0 text-muted" aria-label="Servizio di Affitto">Affitto</a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href={"/Immobili?Tipologia="+encodeURIComponent("Vendita")} className="nav-link p-0 text-muted" aria-label="Servizio di Vendita">Vendita</a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="#" className="nav-link p-0 text-muted" aria-label="Servizio di Consulenze">Consulenze</a>
                        </li>
                    </ul>
                </div>
                <div className="col mb-3 txt-black">
                    <h5>Social</h5>
                    <hr className="card-border w-50"/>
                    <ul className="nav flex-column" aria-label="Social media">
                        <li className="nav-item mb-2">
                            <a href="https://www.facebook.com/lacasapatella" className="nav-link p-0 text-muted" aria-label="Facebook">
                                <i className="fab fa-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="https://www.instagram.com/la_casa_patella/" className="nav-link p-0 text-muted" aria-label="Instagram">
                                <i className="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="https://www.youtube.com/@lacasapatella1132" className="nav-link p-0 text-muted" aria-label="YouTube">
                                <i className="fab fa-youtube" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col mb-3 txt-black">
                </div>
                <div className="col mb-3 txt-red">
                </div>
            </section>
            <section>
                <p className="footer-end">Copyright 2024 | <a href="/CookiePolicy">Cookie policy</a> | <a href="/PrivacyPolicy">Privacy policy</a></p>
            </section>
        </footer>
    )
}

export default FooterComponent;