import "./Loader.css"

function LoaderComponent(){
    return (
        <div className="loader-container">
            <span className="loader blur-in-text"></span>
        </div>
    );
}

export default LoaderComponent;