import { useEffect, useRef, useState } from 'react';
import "./Card.css";
import "./SkeletonCard.css";

export interface Card {
  Id: string;
  Alt: string;
  Title: string;
  Hero?: string;
}

export interface CardProps{
  card: JSX.Element;
}

interface CardContainerProps {
  listCard: JSX.Element[];
  noElementMessage?: string;
  waitTime?: number;
}

function CardComponent({ card } : CardProps) {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target instanceof HTMLElement) {
            entry.target.classList.add("card-fade-in");
            (entry.target as HTMLDivElement).getElementsByClassName("card-body")[0].classList.add("blur-in-text", "delay-02");
          }
        } else if((entry.boundingClientRect.top && entry.rootBounds!.top) && entry.boundingClientRect.top > entry.rootBounds!.top && entry.target.classList.contains("card-fade-in")) {
          if (entry.target instanceof HTMLElement) {
            entry.target.classList.remove("card-fade-in");
            (entry.target as HTMLDivElement).getElementsByClassName("card-body")[0].classList.remove("blur-in-text", "delay-02");
          }
        }
      });
    }, 
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.05,
    });
    
    if (cardRef.current) {
        observer.observe(cardRef.current);
    }

    return () => {
        if (cardRef.current) {
            observer.unobserve(cardRef.current);
        }
    };
  }, []);

  return (
    <div className='card-animation-enter' ref={cardRef}>
      {card}
    </div>
  );
}

export function SkeletonCard() {
  return (
    <div className="card card-item loading-card">
      <div className="card-item--container-img loading-img">
        <div className="skeleton skeleton-img"></div>
      </div>
      <div className="card-body">
        <div className="skeleton skeleton-title"></div>
        <div className="skeleton skeleton-text"></div>
        <div className="skeleton skeleton-btn"></div>
      </div>
    </div>
  )
}

export function CardContainerComponent({ listCard, noElementMessage, waitTime }: CardContainerProps) {
  const [noElMsg, setNoElementMessage] = useState<string | null>(null);
  const timeToWait = waitTime ?? 5000;
  
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (listCard.length === 0) {
      timer = setTimeout(() => {
        setNoElementMessage(noElementMessage ?? "Nessun elemento disponibile al momento");
      }, timeToWait);
    } else {
      setNoElementMessage(null);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [listCard]);

  if (noElMsg != null) {
    return (
      <div className="no-items-message">
        <i className="fa-solid fa-face-frown"></i>
        <p>{noElMsg}</p>
      </div>
    );
  }
  
  if(listCard.length === 0){
    return (
      <div className="card-container">
        {[...Array(3)].map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </div>
    );
  }
  
  return (
    <div className="card-container">
      {listCard.map((card, index) => (
        <CardComponent key={index} card={card} />
      ))}
    </div>
  );
}