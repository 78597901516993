import { useCallback, useEffect, useRef, useState } from "react";
import { AdModel } from "../Models/AdModel";
import { apiGet } from "../RTFramework/Services/Api";
import { ConditionOperator, WhereCondition } from "../RTFramework/Utility/StringOption";
import { useLocation } from "react-router-dom";

interface FilterCriteria {
  tipo: string;
  vani: number | null;
  minPrice: number;
  maxPrice: number;
}

export const useAdsFilter = (adsLimit: number) => {
  const [ads, setAds] = useState<AdModel[]>([]);
  const [filteredAds, setFilteredAds] = useState<AdModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [filters, setFilters] = useState<FilterCriteria>({
    tipo: "",
    vani: null,
    minPrice: 0,
    maxPrice: 5000000
  });
  const resetRef = useRef<Boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Funzione per recuperare gli annunci
  const fetchAds = useCallback(async (offset: number = ads.length) => {
    if (!loading) {
      setLoading(true);
      if (resetRef.current) {
        offset = 0;
      }
      try {
        const whereConditions: WhereCondition[] = [];

        const savedFilters = sessionStorage.getItem("filters");
        const filterTemp = savedFilters ? JSON.parse(savedFilters) : filters;
  
        if (filterTemp.tipo) whereConditions.push(new WhereCondition("Type", filterTemp.tipo));
        if (filterTemp.vani !== null && filterTemp.vani !== 0) whereConditions.push(new WhereCondition("Vani", filterTemp.vani.toString()));
        whereConditions.push(new WhereCondition("Prezzo", filterTemp.minPrice.toString(), ConditionOperator.GREATER_EQUAL));
        whereConditions.push(new WhereCondition("Prezzo", filterTemp.maxPrice.toString(), ConditionOperator.LESS_EQUAL));
  
        const response = await apiGet("Ad", "Get", adsLimit, offset, whereConditions, [], false);
        const newAds = response.data as AdModel[];
  
        if (resetRef.current) {
          setAds(newAds);
          setFilteredAds(newAds); 
          resetRef.current = false;
        } else {
          const adsMerged = [...ads, ...newAds.filter(ad => !ads.some(existingAd => existingAd.AnnuncioId === ad.AnnuncioId))];
          setAds(adsMerged);
          applyFilter(adsMerged);
        }
  
        if (newAds.length === 0) { setHasMoreData(false) };
      } catch (error) {
        console.error("Errore nel caricamento annunci:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [ads, adsLimit, filters, loading]);  

  // Effetto per recuperare i filtri salvati da sessionStorage all'avvio
  useEffect(() => {
    const savedFilters = sessionStorage.getItem("filters");
    console.log(savedFilters);
    console.log(queryParams.get("Tipologia"));
    if(queryParams.get("Tipologia")){
      setFilter({ tipo: queryParams.get("Tipologia")! })
    }
    else if (savedFilters) {
      const filter = JSON.parse(savedFilters);
      setFilters(filter);
    }
  }, []); // Inizializzazione dei filtri

  useEffect(() => {
    fetchAds();
  }, [filters])

  // Funzione per applicare il filtro sugli annunci
  const applyFilter = useCallback((adsList: AdModel[]) => {
    const filtered = adsList.filter((ad) => {
      const typeMatch = !filters.tipo || ad.Type === filters.tipo;
      const roomMatch = filters.vani !== null && filters.vani !== 0 ? ad.Vani === filters.vani : true;
      const priceMatch = ad.Prezzo >= filters.minPrice && ad.Prezzo <= filters.maxPrice;
      return typeMatch && roomMatch && priceMatch;
    });
    setFilteredAds(filtered);
  }, [filters]);

  // Funzione per aggiornare i filtri e salvarli su sessionStorage
  const setFilter = useCallback((newFilters: Partial<FilterCriteria>) => {
    setFilters((prev) => {
      const updatedFilters = { ...prev, ...newFilters };
      sessionStorage.setItem("filters", JSON.stringify(updatedFilters)); // Salvataggio su sessionStorage
      return updatedFilters;
    });
    resetRef.current = true;
    setHasMoreData(true); // Reset di hasMoreData
  }, []);

  return { filteredAds, setFilter, fetchMoreAds: fetchAds, hasMoreData, loading };
};
