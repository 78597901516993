import "../Header.css";

export interface HeaderProp {
    Title: string;
    SrcImg?: string;
}

function HeaderComponent({Title, SrcImg}: HeaderProp){
    return (
        <header className="header header-immobili" style={SrcImg ? { backgroundImage: `url(${SrcImg})` } : {}}>
            <div className="overlay"></div>
            <div className="header-content">
                <h2>{Title}</h2>
            </div>
        </header>
    )
}

export default HeaderComponent;