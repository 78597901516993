import React, { useCallback, useEffect, useRef, useState } from "react";
import "./Filter.css";
import { useLocation } from "react-router-dom";

interface FilterComponentProps {
  onFilterChange: (filters: Partial<{ tipo: string; vani: number | null; minPrice: number; maxPrice: number }>) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ onFilterChange }) => {
  const tipoRef = useRef<string>("");
  const roomRef = useRef<string>("");
  const [tempMinPrice, setTempMinPrice] = useState<number>(0);  // Stato per valore temporaneo
  const [tempMaxPrice, setTempMaxPrice] = useState<number>(tipoRef.current === "Affitto" ? 5000 : 5000000); // Stato per valore temporaneo
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Funzione di aggiornamento del filtro con debounce
  const handleFilterChange = useCallback(
    (newFilters: Partial<{ tipo: string; vani: number | null; minPrice: number; maxPrice: number }>) => {
      // Cancella il timeout precedente
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Imposta il timeout per applicare il filtro dopo 500ms (debounce)
      timeoutRef.current = setTimeout(() => {
        onFilterChange(newFilters);
      }, 500);
    },
    [onFilterChange]
  );

  // Gestione dei cambiamenti sui filtri
  const handleTipoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    tipoRef.current = event.target.value;
    onFilterChange({ tipo: event.target.value });
  };

  const handleRoomChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    roomRef.current = event.target.value;
    onFilterChange({ vani: Number(roomRef.current) });
  };

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if(newValue < tempMaxPrice){
        setTempMinPrice(newValue);  // Aggiorna il valore temporaneo
        handleFilterChange({ minPrice: newValue });  // Invia il filtro con debounce
    }
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if(newValue > tempMinPrice){
        setTempMaxPrice(newValue);  // Aggiorna il valore temporaneo
        handleFilterChange({ maxPrice: newValue });  // Invia il filtro con debounce
    }
  };

  useEffect(() => {
    // Inizializza i valori di sessionStorage all'avvio del componente
    const savedFilters = sessionStorage.getItem("filters");
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      tipoRef.current = queryParams.get("Tipologia") || filters.tipo;
      roomRef.current = filters.vani;
      setTempMinPrice(filters.minPrice || 0);
      setTempMaxPrice(filters.maxPrice || (tipoRef.current === "Affitto" ? 5000 : 5000000));
    }
  }, []);

  return (
    <div className="wrapper-search" role="search" aria-label="Modulo per applicare i filtri di ricerca">
      <div className="search-bar-container mt-4">
        <div className="search-bar-item">
          <label htmlFor="tipologia">Tipologia</label>
          <select
            id="tipologia"
            className="form-select"
            onChange={handleTipoChange}
            aria-label="Seleziona tipologia di contratto"
            value={tipoRef.current}
          >
            <option value={""}>Tutti</option>
            <option value={"Vendita"}>Vendita</option>
            <option value={"Affitto"}>Affitto</option>
          </select>
        </div>

        <div className="search-bar-item">
          <label htmlFor="locali">Locali</label>
          <select
            id="locali"
            className="form-select"
            onChange={handleRoomChange}
            aria-label="Seleziona numero di locali"
            value={roomRef.current}
          >
            <option value={""}>Tutti</option>
            <option value={1}>1 locale</option>
            <option value={2}>2 locali</option>
            <option value={3}>3 locali</option>
            <option value={4}>4 locali</option>
            <option value={5}>5 locali e oltre</option>
          </select>
        </div>

        <div className="search-bar-item range-bg">
          <label htmlFor="prezzoMin" className="form-label">Min</label>
          <input
            type="range"
            className="form-range"
            min={0}
            max={tipoRef.current === "Affitto" ? 4999 : 4999999}
            step={tipoRef.current === "Affitto" ? 50 : 500}
            id="prezzoMin"
            value={tempMinPrice}
            onChange={handleMinPriceChange}
            aria-labelledby="prezzoMinLabel"
            aria-describedby="rangeValueMin"
          />
          <span className="range-value" id="rangeValueMin">
            {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(tempMinPrice)}
          </span>
        </div>

        <div className="search-bar-item range-bg">
          <label htmlFor="prezzoMax" className="form-label">Max</label>
          <input
            type="range"
            className="form-range"
            min={0}
            max={tipoRef.current === "Affitto" ? 5000 : 5000000}
            step={tipoRef.current === "Affitto" ? 50 : 500}
            id="prezzoMax"
            value={tempMaxPrice}
            onChange={handleMaxPriceChange}
            aria-labelledby="prezzoMax"
            aria-describedby="rangeValueMax"
          />
          <span className="range-value" id="rangeValueMax">
            {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(tempMaxPrice)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
