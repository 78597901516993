import { useState } from "react";
import "./Nav.css";

function NavComponent(){
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsExpanded(!isExpanded);
    };
    
    return (
        <nav className="navbar navbar-expand-lg navbar-light" role="navigation" aria-label="Barra di navigazione principale">
            <a className="navbar-brand" href="/Home" aria-label="Homepage - La Casa Patella" role="link">
                <img src="/logogreen.png" alt="La Casa Patella"/>
            </a>
            <button className="navbar-toggler" type="button" 
                data-toggle="collapse" data-target="#navbarNav" 
                aria-controls="navbarNav"
                onClick={toggleNavbar}
                aria-expanded={isExpanded}
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isExpanded ? 'show' : ''}`} id="navbarNav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="/Home" aria-label="Homepage - La Casa Patella" role="link">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={"/Immobili?Tipologia="+encodeURIComponent("Affitto")} aria-label="Immobili - La Casa Patella" role="link">Affitto</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={"/Immobili?Tipologia="+encodeURIComponent("Vendita")} aria-label="Immobili - La Casa Patella" role="link">Vendita</a>
                    </li>
                    {/*<li className="nav-item">
                        <a className="nav-link" href="/Servizi">Servizi</a>
                    </li>*/}
                    {/*<li className="nav-item">
                        <a className="nav-link" href="/Contatti">Contatti</a>
                    </li>*/}
                </ul>
            </div>
        </nav>
    )
}

export default NavComponent;