import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import Immobile from './Pages/Immobile/Immobile';
import Immobili from './Pages/Immobili';
import Cookie from './Pages/Policy/Cookie';
import Privacy from './Pages/Policy/Privacy';
import Servizi from './Pages/Servizi/Servizi';

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/Home" element={<Home/>} />
          <Route path="/Immobili" element={<Immobili/>} />
          <Route path="/Immobile" element={<Immobile/>} />
          <Route path="/Servizi" element={<Servizi/>} />
          <Route path="/PrivacyPolicy" element={<Privacy/>} />
          <Route path="/CookiePolicy" element={<Cookie/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
