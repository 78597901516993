import FooterComponent from "../../Components/Footer/FooterComponent";
import NavComponent from "../../Components/Nav/NavComponent";
import "./Policy.css";

export default function Cookie(){
    return (
        <>
            <NavComponent/>
            <section className="policy">
                <h4>Cookie policy</h4>
                <p>
                    Ai sensi della vigente normativa in materia di protezione dei dati personali ("Normativa Privacy"), Regolamento UE 2016/679 ("GDPR") e il 
                    Provvedimento del Garante per la protezione dei dati personali ("Garante Privacy") n. 229/2014 sull'individuazione delle modalità semplificate 
                    per l'informativa e l'acquisizione del consenso per l'uso dei cookie, “La Casa” Patella Servizi Immobiliari di Patella Fausta, in qualità di 
                    titolare del trattamento (il "Titolare") fornisce le seguenti informazioni relative ai cookie installati sul sito web www.dove.it (il "Sito").
                </p>

                <h5>Cosa sono i cookies</h5>
                <p>
                    I cookie sono piccoli file memorizzati sul computer dell’utente che sono necessari per il corretto funzionamento del sito e per permettere la 
                    navigazione dell’utente (noti anche come cookie tecnici); in aggiunta a questi, possono essere usati in via opzionale anche per finalità di 
                    marketing e per inviare messaggi pubblicitari in linea con le preferenze dell’utente (cd. cookie di profilazione).
                    Altri tipi di cookie sono i cookie di sessione, che vengono cancellati immediatamente alla chiusura del browser di navigazione e i cookie persistenti, 
                    che rimangono invece all’interno del browser per un determinato periodo di tempo (utilizzati, ad esempio, per riconoscere il dispositivo che si 
                    collega ad un sito agevolando le operazioni di autenticazione per l’utente), cookie di “terze parti”, che sono generati e gestiti da soggetti 
                    diversi dal gestore del sito web sul quale l’utente sta navigando.
                </p>

                <h5>Cookies utilizzati sul sito</h5>
                <p>
                    Utilizzando il Sito, l'utente acconsente all’utilizzo dei cookie in conformità con questa Privacy Policy. 
                    Il Sito utilizza le seguenti tipologie di cookie:
                    <ul>
                        <li>
                            cookie di sessione, necessari per consentire la navigazione sul Sito e il suo funzionamento, per finalità di sicurezza interna e di 
                            amministrazione del sistema;
                        </li>
                        <li>
                            cookie di funzionalità, di sessione e persistenti, necessari per consentire all’utente di utilizzare elementi multimediali presenti sul Sito, 
                            come ad esempio immagini e video;
                        </li>
                        <li>
                            cookie analitici, utilizzati dal Sito per inviare informazioni statistiche al sistema Google Analytics, attraverso il quale “La Casa” Patella Servizi Immobiliari di Patella Fausta può 
                            effettuare analisi statistiche degli accessi/visite al Sito. I cookie utilizzati perseguono esclusivamente scopi statistici e raccolgono informazioni in forma aggregata. Attraverso una 
                            coppia di cookie, di cui uno persistente e l’altro di sessione (con scadenza alla chiusura del browser), Google Analytics salva anche un registro con gli orari di 
                            inizio della visita al Sito e di uscita dallo stesso.
                        </li>
                        <li>
                            cookie terze parti, persistenti, utilizzati dal Sito per includere nelle sue pagine i pulsanti di alcuni social-network (Facebook, X, Instagram). Selezionando uno di questi 
                            pulsanti, l’utente può pubblicare sulla propria pagina personale del relativo social network i contenuti della pagina web del Sito che sta visitando oppure visitare il profilo 
                            social presente su tali siti di “La Casa” Patella Servizi Immobiliari di Patella Fausta.
                        </li>
                    </ul>
                </p>

                <p>
                    Il Sito potrebbe contenere link ad altri siti (cd. siti terzi). “La Casa” Patella Servizi Immobiliari di Patella Fausta non effettua alcun 
                    accesso o controllo su cookie, web beacon e altre tecnologie di tracciamento degli utenti che potrebbero essere utilizzate dai siti terzi cui 
                    l’utente può accedere dal Sito; “La Casa” Patella Servizi Immobiliari di Patella Fausta non effettua alcun controllo su contenuti e materiali 
                    pubblicati da o ottenuti attraverso siti terzi, né sulle relative modalità di trattamento dei dati personali dell’utente, ed espressamente declina 
                    ogni relativa responsabilità per tali eventualità. L’utente è tenuto a verificare la privacy policy dei siti terzi cui accede tramite il Sito e ad 
                    informarsi circa le condizioni applicabili al trattamento dei propri dati personali. La presente Privacy Policy si applica solo al Sito come sopra 
                    definito.
                </p>

                <h5>Come disabilitare e/o cancellare i cookies nei browser</h5>
                <p>
                    Se lo desidera può gestire direttamente i cookie anche attraverso le impostazioni del suo browser. 
                    Tuttavia, cancellando i cookies dal browser potrebbe rimuovere le preferenze che ha impostato per il sito lacasapatella.it, 
                    per questo sarebbe opportuno che visitasse periodicamente questa pagina per ricontrollare le sue preferenze. Per ulteriori informazioni e 
                    supporto è possibile anche visitare la pagina di aiuto specifica del web browser che si sta utilizzando (a titolo esemplificativo Firefox, Safari, 
                    Chrome, Opera).
                </p>
            </section>
            <FooterComponent/>
        </>
    )
}