import { LikeCondition, StringOption, WhereCondition } from "../Utility/StringOption";

const baseUrl = (process.env.REACT_APP_API_BASE_URL as string);

interface AuthResponse {
    isLogin: boolean;
    authToken: string;
}
export interface MenuResponse {
    items: any[],
    error: boolean
}
export interface GetResponse {
    error: boolean;
    data: any;
}
interface UpdateResponse {
    error: boolean;
}
interface InsertResponse {
    error: boolean;
    itemId: number | null;
}
interface InsertPhotoResponse {
    error: boolean;
    itemId: string | null;
}
interface DeleteResponse {
    error: boolean;
}

export const apiGet = async (controllerName: string, callbackFunction: string, limit: number | null, offset: number | null, whereCondition: WhereCondition[] = [], likeCondition: LikeCondition[] = [], isAuth: boolean = true): Promise<GetResponse> => {
    const stringOption = new StringOption(limit, offset, whereCondition, likeCondition);
    const payload = StringOption.stringify(stringOption);
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }

    try {
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers,
            body: payload,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data && Array.isArray(data)) {
            return {error: false, data: data};
        } else if(data && typeof data === 'object') {
            return {error: false, data: data}
        } else {
            console.error("Invalid data format:", data);
            return {error: true, data: []};
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return {error: true, data: []};
    }
}

export const apiSet = async (controllerName: string, callbackFunction: string, item: any, isAuth: boolean = true): Promise<UpdateResponse> => {
    const payload = `item=${encodeURIComponent(JSON.stringify(item))}`;
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }
    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers,
            body: payload,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (!data.error) return { error: data.error };
        
        console.error("Login failed", data.message);
        return { error: true };
    } catch(error){
        console.error("Errore: ", error);
        return { error: true };
    }
}

export const apiPut = async (controllerName: string, callbackFunction: string, item: any, isAuth: boolean = true): Promise<InsertResponse> => {
    const payload = `item=${JSON.stringify(item)}`;
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }
    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers,
            body: payload,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (!data.error) return { error: false, itemId: data.id as number};
        
        console.error("Login failed", data.message);
        return { error: true, itemId: null };
    } catch(error){
        console.error("Errore: ", error);
        return { error: true, itemId: null };
    }
}

export const apiPutPhoto = async (controllerName: string, callbackFunction: string, item: any, isAuth: boolean = true): Promise<InsertPhotoResponse> => {
    const payload = `item=${item}`;
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }
    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers,
            body: payload,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (!data.error) return { error: false, itemId: data.Id as string };

        console.error("Login failed", data.message);
        return { error: true, itemId: null };
    } catch (error) {
        console.error("Errore: ", error);
        return { error: true, itemId: null };
    }
}

export const apiDelete = async (controllerName: string, callbackFunction: string, itemId: any, isAuth: boolean = true): Promise<DeleteResponse> => {
    const payload = `itemId=${JSON.stringify(itemId)}`;
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }
    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers,
            body: payload,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (!data.error) return { error: false };
        
        console.error("Login failed", data.message);
        return { error: true };
    } catch(error){
        console.error("Errore: ", error);
        return { error: true };
    }
}

export const apiGetSelectOption = async (controllerName: string, callbackFunction: string, isAuth: boolean = true)  => {
    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    
    if (isAuth) {
        const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
        headers['Authorization'] = `Bearer ${token![2]}`;
    }
    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: headers
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data && Array.isArray(data)) {
            return data;
        } else {
            console.error("Invalid data format:", data);
            return [];
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

export const getAuthToken = async (controllerName: string, callbackFunction: string, username: string, password: string): Promise<AuthResponse> => {
    const payload = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

    const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: payload,
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (!data.error && data.jwt != "") return { isLogin: true, authToken: data.jwt };
        
        console.error("Login failed", data.message);
        return { isLogin: false, authToken: "" };
    } catch(error){
        console.error("Errore: ", error);
        return { isLogin: false, authToken: "" };
    }
}

export const setLogout = async (controllerName: string, callbackFunction: string): Promise<boolean> => {
    const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
    
    if (!token) {
        console.error("No auth token found in cookies.");
        return false;
    }

    try{
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token[2]}`
            },
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        
        if (data.error) {
            console.error("Logout failed", data.message);
            return false;
        }

        return true;
    } catch (error) {
        console.error("Error during setLogout:", error);
        return false;
    }
}

export const getMenuItems = async (controllerName: string, callbackFunction: string): Promise<MenuResponse> => {
    const token = document.cookie.match(new RegExp('(^| )authToken=([^;]+)'));
    
    if (!token) {
        console.error("No auth token found in cookies.");
        return { items: [], error: true};
    }

    try {
        const response = await fetch(`${baseUrl}/${controllerName}/${callbackFunction}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token[2]}`
            },
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        
        if (data.error) {
            console.error("Logout failed", data.message);
            return { items: [], error: true };
        }

        return { items: data, error: false };
    } catch (error) {
        console.error("Error during setLogout:", error);
        return { items: [], error: true };
    }
};