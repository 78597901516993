import "../Header.css";

function HeaderComponent(){
    return (
        <header className="header" role="banner">
            <div className="overlay"></div>
            <div className="header-content">
                <h1>La Casa Patella</h1>
                <hr/>
                <h3>Servizi immobiliari</h3>
            </div>
        </header>
    )
}

export default HeaderComponent;