import "./MessageOverlayBanner.css";

const isWorkingProgress = (process.env.REACT_APP_WORKING_PROGRESS as string) == "true";

export default function MessageOverlayBanner(){
    return (
        <>
            {isWorkingProgress && <div className="overlay-banner">
                <div className="text-center">
                    <h1>Sito in allestimento</h1>
                    <p>Torna presto a trovarci!</p>
                </div>
            </div>}
        </>
    );
}