import FooterComponent from "../../Components/Footer/FooterComponent";
import NavComponent from "../../Components/Nav/NavComponent";
import "./Policy.css";

export default function Privacy(){
    return (
        <>
            <NavComponent/>
            <section className="policy">
                <h4>Privacy policy</h4>
                <h5>Introduzione</h5>
                <p>
                    “La Casa” Patella Servizi Immobiliari di Patella Fausta prende in seria considerazione la privacy dell’utente e si impegna nel rispetto della stessa. 
                    La presente privacy policy (“Privacy Policy”) descrive le attività di trattamento dei dati personali realizzate da “La Casa” Patella Servizi Immobiliari 
                    di Patella Fausta tramite il sito lacasapatella.it (“sito”) e i relativi impegni assunti in tal senso dalla Società. “La Casa” Patella Servizi Immobiliari 
                    di Patella Fausta può trattare i dati personali dell’utente quando questi visita il Sito e utilizza i servizi e le funzionalità presenti sul Sito. 
                    Nelle sezioni del Sito in cui sono raccolti i dati personali dell’utente è pubblicata una specifica informativa ai sensi degli artt. 13 e 15 del Regolameto UE 
                    679/2016. Ove previsto dal Regolamento UE 679/2016, sarà richiesto il consenso dell’utente prima di procedere al trattamento dei suoi dati personali. 
                    Se l’utente fornisce dati personali di terzi, deve provvedere affinché la comunicazione dei dati a “La Casa” Patella Servizi Immobiliari di Patella Fausta 
                    e il successivo trattamento per le finalità specificate nell’informativa privacy applicabile sia conforme al Regolamento UE 679/2016 e alla normativa applicabile.
                </p>

                <h5>Titolare del trattamento</h5>
                <p>
                    Il Titolare del trattamento dei dati è “La Casa” Patella Servizi Immobiliari di Patella Fausta con sede in Viale Vittorio Veneto n. 18 20124 Milano (MI).
                    Mail: info@lacasapatella.it
                    Pec: patella.fausta@pec.it
                </p>

                <h5>Tipologia di dati trattati e oggetto del trattamento</h5>
                <p>
                    Il trattamento avrà ad oggetto singole operazioni o un insieme di operazioni di trattamento, quali a titolo esemplificativo: raccolta, registrazione, 
                    organizzazione, conservazione, elaborazione, comunicazione, modifica, selezione e utilizzo dei dati personali forniti dall’Utente durante l’utilizzo 
                    dei servizi offerti da La Casa” Patella Servizi Immobiliari di Patella Fausta tramite il Sito.
                    La visita e la consultazione del Sito non comportano in genere raccolta e trattamento dei dati personali dell’utente salvo che per i dati di 
                    navigazione e i cookie come di seguito specificato. In aggiunta ai cosiddetti “dati di navigazione”, potranno essere oggetto di trattamento 
                    dati personali volontariamente forniti dall’utente quando questi interagisce con le funzionalità del Sito o chiede di fruire dei servizi offerti 
                    sul Sito. Nel rispetto del Codice Privacy, “La Casa” Patella Servizi Immobiliari di Patella Fausta potrebbe altresì raccogliere i dati personali 
                    dell’utente presso terzi nello svolgimento della propria attività.
                    A titolo esemplificativo possono essere raccolti dati identificativi e di contatto, forniti dall'utente in caso di registrazione al Sito ovvero 
                    nel caso di richieste informative trasmesse alla Società, comprendenti, tra l'altro, nome, cognome, indirizzo e-mail, indirizzo e numero di telefono 
                    di rete fissa e/o cellulare.
                </p>

                <h5>Finalità del trattamento</h5>
                <p>
                    “La Casa” Patella Servizi Immobiliari di Patella Fausta può trattare i dati personali comuni e sensibili dell’utente per le seguenti finalità 
                    (art. 6 lett. b) Regolamento UE 679/2016): 
                        <ul>
                            <li>utilizzo da parte degli utenti di servizi e funzionalità presenti sul Sito</li>
                            <li>gestione delle richieste e segnalazioni da parte dei propri utenti</li>
                            <li>adempimento agli obblighi previsti dalla legge, da un regolamento, dalla normativa comunitaria o da un ordine dell'Autorità</li>
                            <li>adempimento degli obblighi connessi alla gestione dei rapporti con gli Utenti</li>
                            <li>esercizio dei diritti del Titolare, ad esempio il diritto di esercizio di un diritto in sede giudiziaria</li>
                        </ul>

                    Inoltre, con l’ulteriore e specifico consenso facoltativo dell’utente (art. 7 Regolamento UE 679/2016), “La Casa” Patella Servizi 
                    Immobiliari di Patella Fausta potrà trattare i dati personali per finalità di marketing, cioè per inviare all’utente materiale promozionale 
                    e/o comunicazioni commerciali attinenti ai servizi della Società, presso i recapiti indicati, sia attraverso modalità e/o mezzi di contatto 
                    tradizionali (quali, posta cartacea, telefonate con operatore, etc.) che automatizzati (quali, comunicazioni via internet, e-mail, sms, 
                    applicazioni per dispositivi mobili quali smartphone e tablet, account di social network, etc.).
                </p>

                <h5>Modalità del trattamento dei dati</h5>
                <p>
                    Il trattamento dei dati personali è realizzato per mezzo delle operazioni indicate all'art. 4 n. 2 Regolamento UE 679/2016 e precisamente: 
                    raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, 
                    interconnessione, blocco, comunicazione, cancellazione e distruzione dei dati.
                    I dati personali sono trattati sia in forma cartacea che elettronica ed immessi nel sistema informativo aziendale nel pieno rispetto del 
                    Regolamento UE 679/2016, compresi i profili di sicurezza e confidenzialità ed ispirandosi ai principi di correttezza e liceità di trattamento. 
                    In conformità al Regolamento UE 679/2016 i dati sono custoditi e conservati per il tempo necessario al raggiungimento delle finalità per i quali 
                    sono trattati e comunque per tutto il tempo in cui Lei deciderà di essere iscritto al nostro sito web.
                    La gestione e la conservazione dei dati avverrà esclusivamente nel territorio dell'Unione Europea.
                </p>

                <h5>Cookies e dati di navigazione</h5>
                <p>
                    Le informazioni relative alla gestione dei cookie nel Sito sono riportate nella sezione cookie policy presente sul Sito, e alla quale per 
                    completezza si rinvia.
                </p>

                <h5>Conservazione dei dati personali</h5>
                <p>
                    I dati personali sono conservati e trattati attraverso sistemi informatici di proprietà di “La Casa” Patella Servizi Immobiliari di Patella Fausta 
                    e gestiti o da terzi fornitori di servizi tecnici; per maggiori dettagli si prega di fare riferimento alla sezione “Ambito di accessibilità dei 
                    dati personali” che segue. I dati sono trattati esclusivamente da personale specificamente autorizzato, incluso il personale incaricato di svolgere 
                    operazioni di manutenzione straordinaria. I dati personali saranno conservati per la durata del contratto e dopo la fine del contratto al fine di 
                    adempiere all'obbligo legale di “La Casa” Patella Servizi Immobiliari di Patella Fausta, comprese le rivendicazioni per eventuali reclami, 
                    in conformità con la legge applicabile, e quindi saranno cancellati o resi anonimi, e in ogni caso per un tempo massimo di 10 anni.
                    Se si acconsente al trattamento dei prodotti e servizi per fini di marketing diretto dopo la scadenza del contratto, elaboreremo i dati fino alla 
                    revoca del consenso.
                </p>

                <h5>Sicurezza e qualità dei dati personali</h5>
                <p>
                    “La Casa” Patella Servizi Immobiliari di Patella Fausta si impegna a proteggere la sicurezza dei dati personali dell’utente e rispetta 
                    le disposizioni in materia di sicurezza previste dalla normativa applicabile (art. 32 Regolamento UE 679/2016) al fine di evitare perdite di dati, 
                    usi illegittimi o illeciti dei dati e accessi non autorizzati agli stessi. Inoltre, i sistemi informativi e i programmi informatici utilizzati da 
                    “La Casa” Patella Servizi Immobiliari di Patella Fausta sono configurati in modo da ridurre al minimo l’uso di dati personali e identificativi; tali 
                    dati sono trattati solo per il conseguimento delle specifiche finalità di volta in volta perseguite. “La Casa” Patella Servizi Immobiliari di Patella 
                    Fausta utilizza molteplici tecnologie avanzate di sicurezza e procedure atte a favorire la protezione dei dati personali degli utenti; ad esempio, i 
                    dati personali sono conservati su server sicuri ubicati in luoghi ad accesso protetto e controllato. L’utente può aiutare “La Casa” Patella Servizi 
                    Immobiliari di Patella Fausta ad aggiornare e mantenere corretti i propri dati personali comunicando qualsiasi modifica relativa al proprio indirizzo, 
                    alla propria qualifica, alle informazioni di contatto, etc.
                </p>

                <h5>Ambito di comunicazione e di accesso dei dati</h5>
                <p>
                    I dati personali dell’utente potranno essere comunicati a:
                    <ul>
                        <li>tutti i soggetti cui la facoltà di accesso a tali dati è riconosciuta in forza di provvedimenti normativi;</li>
                        <li>ai nostri collaboratori, dipendenti, nell'ambito delle relative mansioni;</li>
                        <li>
                            a tutte quelle persone fisiche e/o giuridiche, pubbliche e/o private quando la comunicazione risulti necessaria o funzionale 
                            allo svolgimento della nostra attività e nei modi e per le finalità sopra illustrate.
                        </li>
                    </ul>
                </p>

                <h5>Natura di conferimento dei dati personali</h5>
                <p>
                    Il conferimento di alcuni dati personali da parte dell’utente è obbligatorio per consentire alla Società di gestire le comunicazioni, 
                    le richieste pervenute dall’utente o per ricontattare l’utente stesso per dar seguito alla sua richiesta. Questo tipo di dati sono 
                    contrassegnati dal simbolo asterisco [*] ed in tal caso il conferimento è obbligatorio per consentire alla Società di dar seguito alla richiesta 
                    che, in difetto, non potrà essere evasa. Al contrario, la raccolta degli altri dati non contrassegnati dall’asterisco è facoltativa: il mancato 
                    conferimento non comporterà alcuna conseguenza per l’utente.
                    Il conferimento dei dati personali da parte dell’utente per finalità di marketing, come specificato nella sezione “Finalità e modalità del trattamento”
                     è facoltativo e il rifiuto di conferirli non avrà alcuna conseguenza. Il consenso conferito per finalità di marketing si intende esteso all’invio di 
                     comunicazioni effettuato attraverso modalità e/o mezzi di contatto sia automatizzati che tradizionali, come sopra esemplificati.
                </p>

                <h5>Diritti dell’interessato</h5>
                <p>
                    Art. 15 (diritto di accesso) e art. 16 (diritto di rettifica) del Regolamento UE 679/2016.
                    L'interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che 
                    lo riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:
                    <ul>
                        <li>le finalità del trattamento;</li>
                        <li>le categorie di dati personali in questione;</li>
                        <li>
                            i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di 
                            paesi terzi o organizzazioni internazionali;
                        </li>
                        <li>il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;</li>
                        <li>
                            l'esistenza del diritto dell'interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la 
                            limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;
                        </li>
                        <li>il diritto di proporre reclamo a un'autorità di controllo;</li>
                        <li>
                            l'esistenza di un processo decisionale automatizzato, compresa la profilazione e, almeno in tali casi, informazioni significative sulla logica 
                            utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato.
                        </li>
                    </ul>
                </p>
                <p>
                    Diritto di cui all’art. 17 del Reg. UE 679/2016 - Diritto alla cancellazione («diritto all'oblio»)
                    L'interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza 
                    ingiustificato ritardo e il titolare del trattamento ha l'obbligo di cancellare senza ingiustificato ritardo i dati personali, 
                    se sussiste uno dei motivi seguenti:
                    <ul>
                        <li>i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;</li>
                        <li>
                            l'interessato revoca il consenso su cui si basa il trattamento conformemente all'articolo 6, paragrafo 1, lettera a), o all'articolo 9, paragrafo 2, 
                            lettera a), e se non sussiste altro fondamento giuridico per il trattamento;
                        </li>
                        <li>
                            l'interessato si oppone al trattamento ai sensi dell'articolo 21, paragrafo 1, e non sussiste alcun motivo legittimo prevalente per procedere al 
                            trattamento, oppure si oppone al trattamento ai sensi dell'articolo 21, paragrafo 2;
                        </li>
                        <li>i dati personali sono stati trattati illecitamente;</li>
                        <li>
                            i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dello Stato membro cui è soggetto il 
                            titolare del trattamento;
                        </li>
                        <li>
                            i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'articolo 8, paragrafo 1 del Reg. 
                            UE 679/2016
                        </li>
                    </ul>
                </p>
                <p>
                    Diritto di cui all’art. 18 - Diritto di limitazione di trattamento
                    L'interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:
                    <ul>
                        <li>l'interessato contesta l'esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali dati personali;</li>
                        <li>il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l'utilizzo;</li>
                        <li>benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all'interessato per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</li>
                        <li>l'interessato si è opposto al trattamento ai sensi dell'articolo 21, paragrafo 1, Reg UE 679/2016 in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell'interessato.</li>
                    </ul>
                </p>
                <p>
                    Diritto di cui all’art. 20 - Diritto alla portabilità dei dati
                    L'interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo 
                    riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da 
                    parte del titolare del trattamento
                </p>

                <h5>Revoca del consenso al trattamento</h5>
                <p>
                    L’interessato facoltà di revocare il consenso al trattamento dei Suoi dati personali, inviando una raccomandata a/r al seguente indirizzo 
                    “La Casa” Patella Servizi Immobiliari di Patella Fausta, Viale Vittorio Veneto n. 18 20124 Milano corredato da fotocopia del suo documento di 
                    identità, con il seguente testo: “revoca del consenso al trattamento di tutti i miei dati personali”, ovvero tramite 
                    Pec all’indirizzo patella.fausta@pec.it. Al termine di questa operazione i Suoi dati personali saranno rimossi dagli archivi nel più breve 
                    tempo possibile.
                </p>
                <p>
                    Se desidera avere maggiori informazioni sul trattamento dei Suoi dati personali, ovvero esercitare i diritti di cui al precedente punto 7, può inviare una 
                    raccomandata a/r al seguente indirizzo “La Casa” Patella Servizi Immobiliari di Patella Fausta, Viale Vittorio Veneto n. 18 20124 Milano, corredato da fotocopia 
                    del suo documento di identità, ovvero tramite Pec all’indirizzo patella.fausta@pec.it.
                </p>
            </section>
            <FooterComponent/>
        </>
    )
}