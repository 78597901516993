import "./ChatTo.css";

export interface ChatToProps{
    subject: string;
    body: string;
}

function MailToComponent({subject, body} : ChatToProps){
    const phoneNumber = "390229529855";
    return (
        <div className="chatto-button">
            <a href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(subject + " " + body)}`} className="nav-link p-0 text-muted" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i>
            </a>
        </div>
    )
}

export default MailToComponent;