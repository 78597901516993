import "./MailTo.css";

export interface MailToProps{
    subject: string;
    body: string;
}

function MailToComponent({subject, body} : MailToProps){
    const mail = "info@lacasapatella.it";
    return (
        <div className="mailto-button">
            <a href={"mailto:"+mail+"?subject="+encodeURI(subject)+"&body="+encodeURI(body)} className="nav-link p-0 text-muted">
                <i className="fas fa-envelope"></i>
            </a>
        </div>
    )
}

export default MailToComponent;