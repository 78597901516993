export class StringOption {
    Limit: number | null;
    Offset: number | null;
    WhereCondition: WhereCondition[] = [];
    LikeCondition: LikeCondition[] = [];

    constructor(limit: number | null, offset: number | null, whereCondition: WhereCondition[] = [], likeCondition: LikeCondition[]) {
        this.Limit = limit;
        this.Offset = offset;
        this.WhereCondition = whereCondition;
        this.LikeCondition = likeCondition;
    }

    static parse(json: string): StringOption {
        const parsedResponse = JSON.parse(json);
        return new StringOption(parsedResponse.Limit, parsedResponse.Offset, parsedResponse.WhereCondition, parsedResponse.LikeCondition);
    }

    static stringify(stringOption: StringOption): string {
        return  "stringOption="+JSON.stringify(stringOption);
    }
}

export enum ConditionOperator {
    EQUAL = "EQUAL",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    GREATER_EQUAL = "GREATER_EQUAL",
    LESS_EQUAL = "LESS_EQUAL"
}
  

export class WhereCondition {
    Field: string = "";
    Value: string = "";
    Symbol: ConditionOperator = ConditionOperator.EQUAL;

    constructor(field: string, value: string, symbol: ConditionOperator = ConditionOperator.EQUAL) {
        this.Field = field;
        this.Value = value;
        this.Symbol = symbol;
    }
}

export class LikeCondition {
    Field: string = "";
    Value: string = "";

    constructor(field: string, value: string) {
        this.Field = field;
        this.Value = value;
    }
}