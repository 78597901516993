import { useEffect, useState } from "react";
import { CardWrapperComponent } from "../../Components/Card/CardComponent";
import FooterComponent from "../../Components/Footer/FooterComponent";
import HeaderComponent from "../../Components/Header/Home/HeaderComponent";
import MessageOverlayBanner from "../../Components/MessageOverlayBanner/MessageOverlayBannerComponent";
import NavComponent from "../../Components/Nav/NavComponent";
import { AdModel } from "../../Models/AdModel";
import { apiGet } from "../../RTFramework/Services/Api";
import { WhereCondition } from "../../RTFramework/Utility/StringOption";
import "./Home.css";

//General variable
const adsLimit = 9;
const controllerName = "Ad";
const callbackFunction = "Get";

function Home(){
    const [ads, setAds] = useState<AdModel[]>([]);
    const [adsFiltered, setAdsFiltered] = useState<AdModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(false);
    const [pathSearch, setPathSearch] = useState<string>("");

    const [tipologia, setTipologia] = useState<string>("");
    const [vani, setVani] = useState<number|null>(null);
    const [minPrice, setMinPrice] = useState<number>(0);
    const [maxPrice, setMaxPrice] = useState<number>(5000000);

    const filterByTipologia = (TypeValue: string) => {
        setTipologia(TypeValue);
        setFilter(true);
    }

    const filterByRooms = (RoomsNumber: number | null) => {
        setVani(RoomsNumber);
        setFilter(true);
    }

    const filterByPrice = (minValue: number, maxValue: number) => {
        setMinPrice(minValue);
        setMaxPrice(maxValue);
        setFilter(true);
    }

    useEffect(() => {
        const params = [
            tipologia ? `Tipologia=${encodeURIComponent(tipologia)}` : "",
            vani ? `Vani=${encodeURIComponent(vani)}` : "",
            minPrice ? `MinPrice=${encodeURIComponent(minPrice)}` : "",
            maxPrice ? `MaxPrice=${encodeURIComponent(maxPrice)}` : ""
        ]
            .filter(Boolean)
            .join("&");
        
        setPathSearch(params ? `?${params}` : "");
    }, [tipologia, vani, minPrice, maxPrice]);

    const applyFilter = () => {
        setAdsFiltered(ads.filter(x => {
            const typeCondition = tipologia !== "" ? x.Type === tipologia : true;
            const vaniCondition = vani !== null ? x.Vani == 5 ? x.Vani >= vani : x.Vani === vani : true;
            const priceCondition = x.Prezzo >= minPrice && x.Prezzo <= maxPrice;
    
            return typeCondition && vaniCondition && priceCondition;
        }));
    }

    useEffect(() => {
        setFilter(false);
        applyFilter();
    }, [filter]);
    

    useEffect(() => {
        const getAds = async () => {
            try{
                setLoading(true);
                const whereCondition: WhereCondition[] = [new WhereCondition("HomePage", "1")];
                if(tipologia !== "") { whereCondition.concat(new WhereCondition("Type", tipologia)); }
                if(vani !== null) { whereCondition.concat(new WhereCondition("Vani", vani.toString())); }

                const offset = 0;//ads.length;
                const data = await apiGet(controllerName, callbackFunction, adsLimit, offset, whereCondition, [], false);
    
                setAds(data.data as AdModel[]);
                setFilter(true);
            }
            catch(error){
                console.error("Errore: ", error);
            }
            finally{
                setLoading(false);
            }
        }
        if(!loading && (ads.length < adsLimit)) { getAds(); }
    }, []);

    return (
        <>
            <MessageOverlayBanner/>
            <NavComponent/>
            <HeaderComponent/>
            {/*<FilterComponent onFilterChange={setFilter} />*/}
            <div className="home-wrapper">
                <CardWrapperComponent listCard={adsFiltered} onClick={() => console.log("click")}/>
            </div>
            <div className="button-container">
                <a className="all-research-button" href={"/Immobili"}>Mostra tutti gli immobili</a>
            </div>
            <FooterComponent/>
        </>
    )
}

export default Home;